import React from "react";
import tw from "twin.macro";
import Header from "../components/Header.js";
import SEO from "../components/Seo";
import Layout from "../layouts/layout.js";
import { H1, P } from "../utils/helpers.js";

const PageWrapper = tw.section`
  px-4 md:px-8 my-8 max-w-3xl mx-auto
`;

const H2 = tw.h3`
text-2xl font-bold my-6
`;


export default function Data() {
  return (
    <Layout>
      <SEO title="Data Deletion Information" />

      <Header bgImage="https://source.unsplash.com/pCVi2aDTQaM/1600x900">
        <H1>Jeevz Data Deletion</H1>
      </Header>

      <PageWrapper>
        <H2>Deleting your user data</H2>

        <P>To delete your user data from Jeevz, navigate to the "Support" screen within the application.  Scroll to the bottom and click the "Delete Account" link.  You'll be prompted to confirm.</P>

        <P>After confirming your request to delete all of your data, Jeevz will begin removing your data from our servers, database, and will issue requests to all third parties that process your data.  Within about 72 hours all of your personally identifiable information will be removed.  Jeevz will still keep a log of your trips and purchase history for regulatory and insurance purposes, but all personally identifiable information will be removed.</P>
      </PageWrapper>
    </Layout>
  )
}
