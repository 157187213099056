import React from "react";
import tw, { styled } from "twin.macro";
import Navigation from "./Navigation";
import Pattern from "../img/bg-pattern.png";
import Img from "gatsby-image";

const HeaderWrapper = styled.div`
  ${tw`relative bg-gray-500 overflow-hidden`}
`;

const Container = tw.div`
  block relative inset-y-0 h-full w-full flex-none pb-8 pt-28 md:pt-36 lg:pt-48 lg:pb-28 overflow-hidden
`;

const Content = styled.div`
  ${tw`relative text-center mb-8 text-white z-20 px-4 md:px-8 lg:max-w-screen-xl mx-auto`}
  & P {
    ${tw`text-xl max-w-xl mx-auto lg:text-2xl`}
  }
`;

const BGOverlay = styled.div`
  ${tw`absolute w-full h-full top-0 left-0 z-10 bg-black opacity-75`}
  background-image: linear-gradient(180deg, rgba(0,0,0,0.7) 0%, rgba(34,39,97,0.7) 100%), url("${Pattern}");
  background-repeat: repeat;
`;

const BGImage = styled.img`
  ${tw`absolute w-full h-full top-0 inset-x-0 object-cover`}
`;

const BGImageWrapper = styled.div`
  ${tw`absolute w-full h-full top-0 inset-x-0 object-cover transform transition duration-500 ease-in-out`}
`;

const Header = ({ bgImage, bgImageFluid, children }) => (
  <HeaderWrapper>
    <Navigation />
    <Container>
      <BGImageWrapper>
        {bgImage && <BGImage src={bgImage} alt="" />}
        {bgImageFluid && <Img fluid={bgImageFluid} alt="" />}
      </BGImageWrapper>
      <BGOverlay />
      <Content>{children}</Content>
    </Container>
  </HeaderWrapper>
);

export default Header;
